<template>
  <v-container fluid>
    <v-data-table
      :headers='headers'
      :items='printers'
      class='elevation-1'
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title> {{$t('PRINTERS')}} </v-toolbar-title>
          <v-spacer />
          <v-icon class='mb-3 mr-3' @click='getPrinters()'>
            mdi-reload
          </v-icon>
          <v-dialog v-model='dialog' max-width='500px' @click:outside='editedItem = defaultItem'>
            <template #activator='{ on, attrs }'>
              <v-btn color='secondary' dark class='mb-2' v-bind='attrs' v-on='on'>
                {{$t('NEW_PRINTER')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class='text-h5'>{{formTitle}}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols='12'>
                      <v-text-field
                        v-model='editedItem.name'
                        :label='$t("NAME")'
                      />
                    </v-col>
                    <v-col cols='12' class='py-0'>
                      <v-checkbox v-model='editedItem.localPrinter' :label='$t("LOCAL_PRINTER")' />
                    </v-col>
                    <v-col v-if='!editedItem.localPrinter' cols='12' sm='9' md='9'>
                      <v-text-field
                        v-model='editedItem.host'
                        :label='$t("HOST")'
                      />
                    </v-col>
                    <v-col v-if='!editedItem.localPrinter' cols='12' sm='3' md='3'>
                      <v-text-field
                        v-model='editedItem.port'
                        :label='$t("PORT")'
                      />
                    </v-col>
                    <v-col v-if='!editedItem.localPrinter' cols='12'>
                      <v-text-field
                        v-model='editedItem.printerUser'
                        auto-complete='new-password'
                        :label='$t("USER")'
                      />
                    </v-col>
                    <v-col v-if='!editedItem.localPrinter'>
                      <v-text-field
                        v-model='editedItem.printerPassword'
                        auto-complete='new-password'
                        :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
                        :type='showPassword ? "text" : "password"'
                        :label='$t("PASSWORD")'
                        @click:append='showPassword = !showPassword'
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color='secondary' text @click='close'>
                  {{$t('CANCEL')}}
                </v-btn>
                <v-btn color='secondary' text @click='save'>
                  {{$t('SAVE')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions='{ item }'>
        <v-icon color='secondary' small class='mr-2' @click='editItem(item)'>
          mdi-pencil
        </v-icon>
        <v-icon color='error' small @click='deleteItem(item)'>
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'Printer',
    data: function () {
      return {
        headers: [
          {
            text: '#',
            align: 'center',
            value: 'id',
          },
          {
            text: this.$t('NAME'),
            align: 'center',
            value: 'name',
          },
          {
            text: this.$t('HOST'),
            align: 'center',
            value: 'host',
          },
          {
            text: this.$t('PORT'),
            align: 'center',
            value: 'port',
          },
          {
            text: this.$t('USER'),
            align: 'center',
            value: 'printer_user',
          },
          {
            text: this.$t('ACTIONS'),
            align: 'center',
            value: 'actions',
            sortable: false,
          },
        ],
        printers: [],
        dialog: false,
        editedItem: {
          id: undefined,
          name: '',
          host: '',
          port: '',
          printerUser: '',
          printerPassword: '',
          localPrinter: false,
        },
        defaultItem: {
          id: undefined,
          name: '',
          host: '',
          port: '',
          printerUser: '',
          printerPassword: '',
          localPrinter: false,
        },
        showPassword: false,
      };
    },
    computed: {
      formTitle: function () {
        return this.editedItem.id === undefined ? this.$t('NEW_PRINTER') : this.$t('EDIT_PRINTER');
      },
    },
    mounted: function () {
      this.getPrinters();
    },
    methods: {
      getPrinters: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/printers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.printers = result.data.printers;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      editItem: function (item) {
        this.editedItem = {
          ...item,
          printerUser: item.printer_user,
          printerPassword: item.printer_password,
          localPrinter: item.local_printer,
        };
        this.dialog = true;
      },
      deleteItem: function (item) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('WARNING'),
          text: this.$t('CONFIRM_DELETE_PRINTER'),
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: this.$t('CONFIRM'),
          cancelButtonText: this.$t('CANCEL'),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.value) {
            try {
              this.$store.dispatch('loading/toggleLoading');
              await axios({
                url: '/printer',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'DELETE',
                data: {
                  id: item.id,
                },
                responseType: 'json',
                withCredentials: true,
              });
              this.getPrinters();
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              console.log(e); /* eslint-disable-line no-console */
              this.$store.dispatch('loading/toggleLoading');
            }
          }
        });
      },
      close: function () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = { ...this.defaultItem };
        });
      },
      save: function () {
        this.savePrinter();
        this.close();
      },
      savePrinter: async function () {
        const newPrinter = this.editedItem.id === undefined;
        try {
          this.$store.dispatch('loading/toggleLoading');
          await axios({
            url: '/printer',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: newPrinter ? 'POST' : 'PUT',
            data: {
              id: this.editedItem.id,
              name: this.editedItem.name,
              host: this.editedItem.localPrinter ? 'localhost' : this.editedItem.host,
              port: this.editedItem.localPrinter ? '21' : this.editedItem.port,
              printerUser: this.editedItem.localPrinter ? 'admin' : this.editedItem.printerUser,
              printerPassword: this.editedItem.localPrinter ? 'admin' : this.editedItem.printerPassword,
              localPrinter: this.editedItem.localPrinter,
            },
            responseType: 'json',
            withCredentials: true,
          });
          this.getPrinters();
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
    },
  };
</script>
