var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('USERS'))+" ")]),_c('v-btn',{staticClass:"ml-3 mt-1",attrs:{"icon":""},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.detailsDialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-plus-outline ")]),_vm._v(" "+_vm._s(_vm.$t('NEW_USER'))+" ")],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('USERS_PAGE_MEANING'))+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"wd-100",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"py-4"},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t("SEARCH"),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"wd-100",attrs:{"multi-sort":"","search":_vm.search,"headers":_vm.userTableHeaders,"items":_vm.list,"item-class":function (item) { return _vm.getTableRowClass(item); },"footer-props":{
          itemsPerPageOptions: [50, 100, 300, -1]
        }},scopedSlots:_vm._u([{key:"item.icon",fn:function(){return [_c('v-avatar',{attrs:{"size":"40","color":"primary lighten-2"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.account.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getRole(item))+" ")])],1)],1)]}},{key:"item.accountStatusCode",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.account.accountStatusCode))+" ")]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formattedDate(item.account.createdAt))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.formattedDateInWords(item.account.createdAt))+" ")])]}},{key:"item.accountCreatorName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.account.accountCreatorName || '---')+" ")]}},{key:"item.lastSignIn",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.details.lastSignIn ? _vm.formattedDateInWords(item.details.lastSignIn) : '-')+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.details.lastSignIn ? _vm.formattedDate(item.details.lastSignIn) : '-')+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.account.accountRoleId !== _vm.ACCOUNT_ROLE.SUPER_ADMIN)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[(item.account.accountStatusId !== _vm.ACCOUNT_STATUS.DISABLED && _vm.getUser.account.id !== item.account.id)?_c('v-list-item',{attrs:{"input-value":true,"color":"error"},on:{"click":function($event){return _vm.disableUser(item)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('DISABLE_USER')))])],1):_vm._e(),(item.account.accountStatusId === _vm.ACCOUNT_STATUS.DISABLED && _vm.getUser.account.id !== item.account.id)?_c('v-list-item',{on:{"click":function($event){return _vm.reactivateUser(item)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-check-circle")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('REACTIVATE_USER')))])],1):_vm._e()],1)],1):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[(_vm.detailsDialog)?_c('v-card',{staticStyle:{"background-color":"#e0e0e0"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"dark":"","color":"primary"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},nativeOn:{"click":function($event){_vm.detailsDialog = false}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,2882372626)},[_c('span',[_vm._v(_vm._s(_vm.$t('CLOSE')))])]),_c('div',{staticClass:"text-h5 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('USER'))+" ")])],1),_c('users-details',{on:{"new-user":_vm.getData,"close":function($event){_vm.detailsDialog = false}}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }