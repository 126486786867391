<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='center'>
      <div class='text-h5'>
        {{$t('USERS')}}
      </div>

      <v-btn icon class='ml-3 mt-1' @click='getData'>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn text @click='detailsDialog = true'>
        <v-icon class='mr-2'>
          mdi-account-plus-outline
        </v-icon>
        {{$t('NEW_USER')}}
      </v-btn>
    </v-row>
    <v-divider class='my-3' />

    <v-row no-gutters class='mb-3'>
      <v-list-item-subtitle>
        {{$t('USERS_PAGE_MEANING')}}
      </v-list-item-subtitle>
    </v-row>

    <v-row no-gutters>
      <v-card flat class='wd-100'>
        <v-card-title class='py-4'>
          <v-spacer />

          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            :label='$t("SEARCH")'
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          class='wd-100'
          multi-sort
          :search='search'
          :headers='userTableHeaders'
          :items='list'
          :item-class='(item) => getTableRowClass(item)'
          :footer-props='{
            itemsPerPageOptions: [50, 100, 300, -1]
          }'
        >
          <template #item.icon>
            <v-avatar size='40' color='primary lighten-2'>
              <v-icon dark>
                mdi-account
              </v-icon>
            </v-avatar>
          </template>

          <template #item.name='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.account.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{getRole(item)}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.accountStatusCode='{ item }'>
            {{$t(item.account.accountStatusCode)}}
          </template>

          <template #item.createdAt='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.account.createdAt)}}
                </div>
              </template>
              {{formattedDateInWords(item.account.createdAt)}}
            </v-tooltip>
          </template>

          <template #item.accountCreatorName='{ item }'>
            {{item.account.accountCreatorName || '---'}}
          </template>

          <template #item.lastSignIn='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{item.details.lastSignIn ? formattedDateInWords(item.details.lastSignIn) : '-'}}
                </div>
              </template>
              {{item.details.lastSignIn ? formattedDate(item.details.lastSignIn) : '-'}}
            </v-tooltip>
          </template>

          <template #item.actions='{ item }'>
            <v-menu
              v-if='item.account.accountRoleId !== ACCOUNT_ROLE.SUPER_ADMIN'
              bottom
              left
            >
              <template #activator='{ on, attrs }'>
                <v-btn
                  icon
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class='pa-0'>
                <v-list-item v-if='item.account.accountStatusId !== ACCOUNT_STATUS.DISABLED && getUser.account.id !== item.account.id' :input-value='true' color='error' @click='disableUser(item)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('DISABLE_USER')}}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if='item.account.accountStatusId === ACCOUNT_STATUS.DISABLED && getUser.account.id !== item.account.id' @click='reactivateUser(item)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('REACTIVATE_USER')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='detailsDialog' fullscreen persistent>
      <v-card v-if='detailsDialog' style='background-color: #e0e0e0;'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('USER')}}
            </div>
          </v-app-bar>

          <users-details @new-user='getData' @close='detailsDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';
  import { mapGetters } from 'vuex';
  import Swal from 'sweetalert2';
  import { ACCOUNT_ROLE, ACCOUNT_STATUS } from '@/store/modules/Auth';

  export default {
    name: 'Users',
    components: {
      UsersDetails: () => import('./UsersDetails'),
    },
    data: function () {
      return {
        ACCOUNT_ROLE: ACCOUNT_ROLE,
        ACCOUNT_STATUS: ACCOUNT_STATUS,
        list: undefined,
        search: undefined,
        userTableHeaders: [
          {
            text: '',
            sortable: false,
            value: 'icon',
            width: '30',
            align: 'center',
          },
          { text: this.$t('NAME'), value: 'name' },
          { text: this.$t('CREATED_BY'), value: 'accountCreatorName' },
          { text: this.$t('CREATION_DATE'), value: 'createdAt' },
          { text: this.$t('LAST_SIGN_IN'), value: 'lastSignIn' },
          { text: this.$t('STATUS'), value: 'accountStatusCode' },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '30',
            align: 'center',
          },
        ],
        detailsDialog: false,
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      formattedDate: function (value) {
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      getTableRowClass: function (item) {
        if (item.account.accountStatusId === ACCOUNT_STATUS.INACTIVATED) {
          return 'grey lighten-2 grey--text text--darken-1';
        }

        if (item.account.accountStatusId === ACCOUNT_STATUS.DISABLED) {
          return 'red lighten-3 grey--text text--darken-2';
        }

        return '';
      },
      getRole: function (item) {
        return this.$t(item.account.accountRoleCode);
      },
      getData: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading', true);
          const { data } = await axios({
            url: '/account-list',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = data && data.list;

          this.$store.dispatch('loading/toggleLoading', false);
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading', false);
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      disableUser: async function (item) {
        try {
          const result = await Swal.fire({
            title: this.$t('WARNING'),
            text: this.$t('REALLY_WANT_DISABLE_USER_ACCOUNT', { name: item.name }),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('CONFIRM'),
          });

          if (!result.value) {
            return;
          }

          this.$store.dispatch('loading/toggleLoading', true);
          await axios({
            url: '/account-delete',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'DELETE',
            responseType: 'json',
            withCredentials: true,
            params: {
              id: item.account.id,
            },
          });

          this.$store.dispatch('loading/toggleLoading', false);
          this.getData();
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading', false);
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      reactivateUser: async function (item) {
        try {
          const result = await Swal.fire({
            title: this.$t('WARNING'),
            text: this.$t('REALLY_WANT_REACTIVATE_USER_ACCOUNT', { name: item.name }),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('CONFIRM'),
          });

          if (!result.value) {
            return;
          }

          this.$store.dispatch('loading/toggleLoading', true);
          await axios({
            url: '/account-reactivate',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'PUT',
            responseType: 'json',
            withCredentials: true,
            params: {
              id: item.account.id,
            },
          });

          this.$store.dispatch('loading/toggleLoading', false);
          this.getData();
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading', false);
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
